import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Helmet } from "react-helmet";
import Events from "../../components/Events";
import Layout from "../../components/Layout";

const events = [
    // {
    //   name: 'Introduction to Mindfulness: 6 Week Online Course',
    //   startDate: '2022-09-14',
    //   endDate: '2022-10-19',
    //   location: 'Online (Zoom)'
    // }
  ];

const IntroCoursePage = () => {
    return <>
    <Helmet title="Will Evans - Meditation Teacher in Totnes, Devon"></Helmet>
    <Layout>
        <section className="overflow-hidden">
        <Container>
            <Row>
            <Col xs={12} lg={7} className="py-5 pe-lg-5">
                <h1>Introduction to Mindfulness - 6 Week Course</h1>
                <p className="lead">
                    The capacity to be mindful, to be awake to the experience of the present moment, is one of the most useful and transformative skills a modern human can develop.
                </p>
                <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7908241/">Mindfulness is strongly linked to happiness and well-being</a> - it is both a support for, and a result of happiness. When we are happy, we are more naturally drawn to being present, to savouring our experience. And when we are mindful, we are not perpetuating habits of mind that cause us stress and tension, so happiness is more naturally available.</p>
                <p>This course is an immersive and in-depth introduction to the practice and principles of mindfulness. We'll learn to meditate, learn how and why different types of meditation work in the ways that they do, and we'll cover practices for bringing mindfulness into everyday life.</p>
                <p>We'll be exploring why it's difficult to meditate, why it's difficult to be present, and how we can make things easier for ourselves by working with our unique strengths and challenges.</p>
                <p>We'll practice embodied speaking and listening, to deepen our capacity to be present in the company of others.</p>
                <p>We'll discover how mindfulness helps to build emotional intelligence, and how we can gain more awareness of unseen emotions.</p>
                <p>And most important of all, we will begin to see how the practice of mindfulness can bring greater freedom, ease and joy to our lives.</p>

                <h5>This course is for you if:</h5>
                <ul>
                    <li>You have used mindfulness apps (Headspace, Calm etc) and would like to go deeper</li>
                    <li>You would like to establish a regular meditation practice</li>
                    <li>You would like to be more present</li>
                    <li>You want to understand how stress and tension are created, and how they can be lessened</li>
                    <li>You often find yourself in the fridge, but you're not sure what you're looking for</li>
                </ul>

                <h5>What you'll need to commit to:</h5>
                <ul>
                    <li>Attending all 6 sessions</li>
                    <li>Meditating every day throughout the course. Help and support will be given with this, but it will also take determination and patience on your part</li>
                    <li>Exploring your own experience with curiosity and sincerity</li>
                </ul>

                <h3>The course structure</h3>
                <table className="course-week-table">
                    <tr>
                        <th>Week 1</th>
                        <td className="week-title">Slowing down</td>
                        <td>Learning to stabilise attention by bringing awareness back to present moment experience.</td>
                    </tr>
                    <tr>
                        <th>Week 2</th>
                        <td className="week-title">Getting comfortable</td>
                        <td>Becoming more familiar with the experience of having a body, learning to find and release tension.</td>
                    </tr>
                    <tr>
                        <th>Week 3</th>
                        <td className="week-title">Reducing reactivity</td>
                        <td>Shedding light on our unconscious reactions, and learning to lessen habits of reactivity.</td>
                    </tr>
                    <tr>
                        <th>Week 4</th>
                        <td className="week-title">Feeling feelings</td>
                        <td>Becoming more aware of our emotional state in any given moment, and finding ways to ease difficult emotions.</td>
                    </tr>
                    <tr>
                        <th>Week 5</th>
                        <td className="week-title">Tuning out the noise</td>
                        <td>Getting familiar with our mental habits, and learning to stop being so seduced by thoughts, and so bothered by them.</td>
                    </tr>
                    <tr>
                        <th>Week 6</th>
                        <td className="week-title">Getting warmer</td>
                        <td>Developing kindness, warmth and goodwill. To yourself, to others and to the pain in your back.</td>
                    </tr>
                </table>
            </Col>
            <Col xs={12} lg={5} className="position-relative events-container p-4 pb-5 mb-lg-0">

                <h3 className="mb-4">Upcoming dates</h3>
                <Events events={[]} />

            </Col>
            </Row>
        </Container>
        </section>
        <section className="bg-eggshell py-4 bg-cover">
        </section>
    </Layout>
  </>
 

}

export default IntroCoursePage;